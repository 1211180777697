import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import archivos from '@demre/client-common/src/plugins/archivos';
import router from './router'
import validator from './utils/validator';
import store from "./store";

import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.use(archivos);
Vue.use(validator);
Vue.config.productionTip = false;

async function init() {
  const { faseActiva, vigente, titulo, proceso, tiposCausales, videosPesd, mensajeCierre } = await fetch(process.env.VUE_APP_SERVER_HOST + '/apelacionInscripcion/configuracion', {
    method: 'GET'
  })
  .then((response) => {
    if(!response.ok) {
      throw new Error('Problema con formulario de apelación. Intente mas tarde.')
    }
    return response.json();
  });

  if(!vigente) {
    const error = new Error(mensajeCierre);
    error.urlVideo =  videosPesd.find(v => v.lugarAsociado == 'Mensaje cierre')?.urlVideo;
    throw error;
  }

  store.commit('setFaseActiva',faseActiva);
  store.commit('setTitulo', titulo);
  store.commit('setProceso', proceso);
  store.commit('setTiposCausales', tiposCausales);
  store.commit('setVideosPesd', videosPesd);
}
init()
.then(async () => {
  new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
  }).$mount('#app');
})
.catch(async (err) => {
  const mensaje = err.message;
  const urlVideo = err.urlVideo
  const { default: Impedimento } = await import( "./Impedimento.vue" /* webpackChunkName: 'impedimento' */);
  new Vue({
  vuetify,
  render: (h) => h(Impedimento, { props: { mensaje, urlVideo } }),
  }).$mount("#app");
});
